import { ButtonLink } from '../ui/ButtonLinks'
import { fetchContent } from '@/utils/FetchContent'
import { useEffect, useState } from 'react'
import minify from 'gqlmin'
import Link from 'next/link'
import Image from 'next/image'
import { dateParser } from '@/utils/Helpers'
import { ComponentImageFragment, ImageSideBySideFragment } from './fragments'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import {
  BlogPosts,
  ComponentFeaturedArticles,
} from 'types/generated/contentful-types'

const FeaturedArticleCard = ({
  title,
  slug,
  publishedDate,
  blogCategory,
  featuredImage,
  cloudinaryFeaturedImage,
  author,
}: BlogPosts): JSX.Element => {
  return (
    <section>
      <Link href={`/the-journal/${slug}`} className="block pb-8 md:pb-0">
        <div className="">
          <Image
            src={cloudinaryFeaturedImage?.[0]?.secure_url ?? featuredImage?.url}
            alt={
              cloudinaryFeaturedImage?.[0]?.metadata?.alt ||
              featuredImage?.description ||
              `${title} curated by ${author?.name}`
            }
            width={384}
            height={256}
          />
          <div className="">
            <p className="pb-0.5 mt-4 fora-text-h8 text-darkStone">
              {dateParser(publishedDate)}
              <span className="px-1">&#8226;</span>
              {blogCategory}
            </p>
          </div>
        </div>
        <div className="w-auto">
          <h2
            className="pb-4 fora-text-h3 md:fora-text-h6"
            dangerouslySetInnerHTML={{
              __html: title as string,
            }}
          />
          <div className="flex items-center gap-3">
            <div className="w-12">
              <Image
                className="rounded-full"
                src={
                  author?.cloudinaryImage?.[0]?.secure_url ?? author?.image?.url
                }
                alt={
                  author?.cloudinaryImage?.[0]?.metadata?.alt ||
                  author?.image?.description ||
                  `Fora Advisor - ${author?.name}`
                }
                width={96}
                height={96}
              />
            </div>
            <div className="pt-px">
              <h2 className="fora-text-body-italic-3">Curated by</h2>
              <p className="fora-text-h7">{author?.name}</p>
            </div>
          </div>
        </div>
      </Link>
    </section>
  )
}

const FeaturedArticles = ({
  articleCategory,
}: ComponentFeaturedArticles): JSX.Element => {
  const [mainArticle, setMainArticle] = useState<BlogPosts>()
  const [secondaryArticles, setSecondaryArticles] = useState<BlogPosts[]>([])
  useEffect(() => {
    const fetchFeaturedArticles = async () => {
      const query = minify(`
      {
        blogPostsCollection(limit: 4,order: publishedDate_DESC, where: {AND: [
          ${articleCategory ? `{ blogCategory: "${articleCategory}" }` : ''},
          { showOnBlogLandingPage:true }
        ]}) {
          items {
            metaTitle
            metaDescription
            __typename
            sys {
              id
            }
            title
            slug
            publishedDate
            blogCategory
            featuredImage {
              url
              description
            }
            cloudinaryFeaturedImage
            content {
              json
            }
            author {
              name
              jobTitle
              image {
                url
                description
              }
            cloudinaryImage
              bio {
                json
              }
            }
            content {
              json 
              links {
                entries {
                  block {
                    __typename
                    sys {
                      id
                    }
                    ... on ComponentImageSideBySide {
                      ${ImageSideBySideFragment}
                    }
                    ... on ComponentImage {
                      ${ComponentImageFragment}
                    }
                    ...on Advisors {
                      title
                      slug
                      firstName
                      image {
                        url
                      }
                      cloudinaryImage
                      expertise
                      advisorVariant
                    }
                    ...on EntryTripReport {
                      title
                      slugId
                      seoSlug
                      image {
                        url
                      }
                      advisor {
                        title
                        image {
                          url
                        }
                        cloudinaryImage
                      }
                    }
                    ...on Itineraries {
                      title
                      slugId
                      seoSlug
                      image {
                        url
                      }
                      advisor {
                        title
                        image {
                          url
                        }
                        cloudinaryImage
                      }
                    }
                  }
                }
                assets {
                  block {
                    sys {
                      id
                    }
                    url
                    title
                    width
                    height
                    description
                  }
                }
              } 
            }
          }
        }
      }
      `)
      const data = await fetchContent(query)

      if (data?.blogPostsCollection?.items?.length > 0) {
        setMainArticle(data.blogPostsCollection.items.shift() as BlogPosts)
        setSecondaryArticles(data.blogPostsCollection.items as BlogPosts[])
      }
    }

    fetchFeaturedArticles()
  }, [])
  const categorySlug = articleCategory
    ?.toLowerCase()
    .replace(/&/gi, 'and') //replace ampersands with literal 'and'
    .replace(/[^a-z0-9\s]/gi, '') //remove all remaining non-alphanumeric, non-whitespace characters
    .replace(/ /gi, '-')
  const viewAllPath = `/the-journal/${categorySlug || ''}`
  const moreThanOneArticle = secondaryArticles.length > 1

  const mainArticleSubCopy = documentToPlainTextString(
    mainArticle?.content?.json as any
  )

  return (
    <section className="relative pb-7 lg:pb-14 mb-14">
      {mainArticle && (
        <div className="grid mb-8 md:mb-6 lg:grid-cols-2">
          <div className="flex order-2 lg:w-10/12">
            <div className="place-self-center">
              <div>
                <Link href={`/the-journal/${mainArticle.slug}`} legacyBehavior>
                  <h1 className="pb-4 cursor-pointer fora-text-h3">
                    {mainArticle?.title}
                  </h1>
                </Link>
              </div>
              <p className="pb-4 fora-text-h8 text-darkStone">
                {dateParser(mainArticle.publishedDate)}
                <span className="px-1">&#8226;</span>
                {mainArticle.blogCategory}
              </p>
              <Link href={`/the-journal/${mainArticle.slug}`} legacyBehavior>
                <div className="flex items-center gap-3 mb-6 md:mb-8">
                  <div className="w-12">
                    <Image
                      className="rounded-full"
                      src={
                        mainArticle?.author?.cloudinaryImage?.[0]?.secure_url ??
                        mainArticle?.author?.image?.url
                      }
                      alt={
                        mainArticle?.author?.cloudinaryImage?.[0]?.metadata
                          ?.alt ||
                        mainArticle?.author?.image?.description ||
                        `Fora Advisor - ${mainArticle?.author?.name}`
                      }
                      width={96}
                      height={96}
                    />
                  </div>

                  <div className="pt-px">
                    <h2 className="fora-text-body-italic-3">Curated by</h2>
                    <p className="fora-text-h7">{mainArticle?.author?.name}</p>
                  </div>
                </div>
              </Link>

              {mainArticleSubCopy && (
                <div className="pb-6 lg:pb-8 fora-text-body-1">
                  <div className="global-richtext">
                    <p className="featured-articles-content">
                      {mainArticleSubCopy}
                    </p>
                  </div>
                </div>
              )}
              <div>
                <ButtonLink
                  href={`/the-journal/${mainArticle.slug}`}
                  target="_self"
                  text="READ MORE"
                  theme="secondary"
                />
              </div>
            </div>
          </div>
          <div className="pb-6 md:pb-8 lg:order-2 lg:pb-0">
            {(mainArticle?.cloudinaryFeaturedImage?.[0]?.secure_url ||
              mainArticle?.featuredImage) && (
              <Image
                src={
                  mainArticle?.cloudinaryFeaturedImage?.[0]?.secure_url ??
                  mainArticle?.featuredImage?.url ??
                  ''
                }
                alt={
                  mainArticle?.cloudinaryFeaturedImage?.[0]?.metadata?.alt ||
                  mainArticle?.featuredImage?.description ||
                  `Fora - ${mainArticle.title}`
                }
                width={600}
                height={600}
                style={{
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </div>
        </div>
      )}

      <div className="block pb-7 md:gap-6 md:grid md:grid-cols-3">
        {secondaryArticles.map((article: any, i: number) => (
          <div key={`ItineraryCard_${i}`} className="lg:w-full">
            <FeaturedArticleCard {...article} />
          </div>
        ))}
      </div>
      {moreThanOneArticle && (
        <div className="flex justify-center">
          <ButtonLink
            href={viewAllPath}
            target="_self"
            text="VIEW ALL"
            theme="secondary"
          />
        </div>
      )}
    </section>
  )
}
export default FeaturedArticles
